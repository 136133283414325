import React, { useContext, useEffect, useState } from "react";
import "./UsersPage.css";
import TheContext from "../../../Storage/theContext";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Autocomplete, TextField } from "@mui/material";
import STRINGS from "../../../assets/Strings";
import { Text } from "../../../elements/MI-elements";
import { getAllProjects, setActive } from "../../../RES/API";

const AllProjects = () => {
    const { Language } = useContext(TheContext);
    let { ProjectsData, CustomersData } = useLoaderData();
    const [projectsData, setProjectsData] = useState(ProjectsData?.data || []);
    const [filter, setFilter] = useState(ProjectsData?.data || []);
    const [loading, setLoading] = useState(-1);
    const navigate = useNavigate();
    const userTableHead = [
        {
            key: "name",
            value: STRINGS.name[Language],
        },
        {
            key: "_active",
            value: STRINGS.status[Language],
        },
    ]

    const RenderUserTableHead = () => {
        return (
            <TableRow>
                {
                    userTableHead.map((user, index) => (
                        <TableCell sx={styles.tableHed} key={index}>
                            <Text style={{ fontWeight: "bold" }}>{user.value}</Text>
                        </TableCell>
                    ))
                }
            </TableRow>
        )
    }
    const RenderUserTableBody = () => {

        const onCellClick = (user) => {
            console.log("cell", user.name);
            navigate(`/${user.name}`, { unstable_viewTransition: true, state: { user } });
        }

        const onStatusCellClick = (e, project_id, status) => {
            setLoading(project_id);
            let type = "Project";
            e.stopPropagation();
            console.log("status", project_id, status);
            setActive(type, project_id, !status).then((res) => {
                if (res?.success) {
                    getData();
                }
                else {
                    alert(res?.message + " something went wrong")
                }
            })
        }

        return projectsData.map((project, i) => (
            <TableRow className="user-row" key={i}>
                {
                    userTableHead.map(({ key }, index) => (
                        <TableCell key={index}
                        // onClick={() => onCellClick(user)}
                        >
                            {key === "_active" ?
                                (loading === project._id ?
                                    <span class="loader"></span> :
                                    <div onClick={(e) => onStatusCellClick(e, project._id, project._active)}
                                        className={`status ${project[key]}`}
                                    />
                                )
                                :
                                <Text
                                    className={project._isAdmin && 'admin'} >
                                    {project[key]}
                                </Text>
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        ));
    }

    const getData = async () => {
        await getAllProjects().then((res) => {
            // console.log("getAllProjects res", res);
            setProjectsData(res?.data);
        }).finally(() => setLoading(-1));
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="users-page">
            <h2>all projects</h2>
            <Autocomplete
                multiple
                value={filter.name}
                onChange={(event, newValue) => {
                    let data = ProjectsData?.data || [];
                    if (newValue.length === 0) {
                        setFilter(data);
                        setProjectsData(data);
                    } else {
                        let filtered = data.filter((project) => newValue.some((customer) => customer._id === project.customer_id));
                        setFilter(filtered);
                        setProjectsData(filtered);
                    }
                }}
                options={CustomersData?.data || []}
                getOptionLabel={(option) => option?.name || option}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} label="customers" />}
                style={{ maxWidth: '1000px', alignSelf: 'center', width: '100%' }}
            />
            <Autocomplete
                multiple
                value={filter.name}
                onChange={(event, newValue) => {
                    let data = ProjectsData?.data || [];
                    if (newValue.length === 0) {
                        setProjectsData(filter);
                    } else {
                        let filtered = data.filter((project) => newValue.some((p) => p._id === project._id));
                        setProjectsData(filtered);
                    }
                }}
                options={filter || []}
                getOptionLabel={(option) => option?.name || option}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} label="projects" />}
                style={{ maxWidth: '1000px', alignSelf: 'center', width: '100%' }}
            />
            <TableContainer className="TableContainer" style={{ maxWidth: '1000px', alignSelf: 'center', width: '100%' }}>
                <Table stickyHeader>
                    <TableHead>
                        <RenderUserTableHead />
                    </TableHead>

                    <TableBody>
                        <RenderUserTableBody />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AllProjects;

const styles = {
    tableHed: { backgroundColor: '#1999', fontWeight: 'bold' },
}