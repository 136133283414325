import "./MonthDays.css";
import React, { useRef, useEffect, useContext, useCallback, } from "react";
import TheContext from "../../../Storage/theContext";
import { Await, useAsyncValue, useLoaderData, useNavigate, useNavigation, } from "react-router-dom";
import { getUserFromStorage, restoreUserLocal } from "../../../Storage/restoreData";
import BoxDaysInMonth from "../../../components/ShiftComponent/BoxDaysInMonth";
import YearMonthPicker from "../../../components/YearMonthPicker ";
import { Title } from "../../../elements/MI-elements";
import STRINGS from "../../../assets/Strings";
import { getWorkingHourMonthShift } from "../../../RES/shifts";
import LottieAnimation from "../../../assets/animation/animation";
import { toast } from "react-toastify";

const MonthDays = () => {
  const { DateStats, Language, allShifts, setAllShifts } = useContext(TheContext);
  const navigate = useNavigate();
  const CustomersAPI = useLoaderData();
  const user = getUserFromStorage();
  let navigation = useNavigation();
  let navigateState = navigation.state !== "idle"
  const { keyMonth, } = DateStats;
  const { date } = keyMonth;
  const [currentMonth, currentYear] = date.split("/");

  const correctNavigate = useCallback(() => {
    //#2999 useCallback export 
    const restoredUser = restoreUserLocal();
    if (!(restoredUser || restoredUser?._level==="worker")) {
      navigate("/");
    }
  }, [navigate]);
  //  ? correctNavigate;

  const createFilterBody = () => {
    const body = { type: "month", worker: user._id, dateKey: date }
    return body
  }
  const getUserShift = async () => {
    const filterShiftBody = createFilterBody();
    console.log("🔹", "filterShiftBody:\n", filterShiftBody, "🔹");
    
    const userShift2 = await getWorkingHourMonthShift(filterShiftBody);
    console.log("🔹", "user Shift:\n", userShift2, "🔹");
    setAllShifts(userShift2?.data || {})
  }

  const MonthPageHed = () => (
    <Title>
      {STRINGS.Month[Language] + " " + currentMonth}
      <div>
        <YearMonthPicker />
      </div>
    </Title>
  )

  const RenderAwaitedData = ({ currentYear, currentMonth }) => {
    console.info("🔹 Data loaded🔹");
    const { setCustomers } = useContext(TheContext);
    const currentDayRef = useRef();
    const data = useAsyncValue();
    // let navigation = useNavigation();
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    const today = new Date().getDate();


    const scrollToCurrentDate = () => {// there is something wrong
      if (currentDayRef.current) {
        currentDayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', });
      }
    };// tody if unseen be sticky

    const RenderShiftDaysOfMonth = () => {
      return Array.from({ length: daysInMonth }, (_, index) => (
        <div key={index} ref={index === today ? currentDayRef : null}>
          <BoxDaysInMonth
            year={currentYear}
            month={currentMonth}
            key={index}
            day={index + 1}
            userShift={allShifts?.[date]}
          />
        </div>
      ));
    };

    useEffect(() => {
      console.log("🔹 data",data);
      setCustomers(data);
      scrollToCurrentDate();

    }, [data, date]);

    return <RenderShiftDaysOfMonth />;
  }

  useEffect(() => {
    correctNavigate()
  }, [correctNavigate]);

  useEffect(() => {
    toast.promise(
      getUserShift(),
      {
        pending: STRINGS.Loading[Language],
        success: `${STRINGS.success[Language]}✅`,
        error: `${STRINGS.failed[Language]}❌`
      }
    )
  }, [date,]);

  return (
    <div style={styles.page}>
      <MonthPageHed />
      <React.Suspense fallback={<LottieAnimation data={"loadSticks"} loop={true} />}>
        {!navigateState && <Await resolve={CustomersAPI.customers}>
          <RenderAwaitedData
            currentMonth={currentMonth}
            currentYear={currentYear}
          />
        </Await>}
      </React.Suspense>
    </div>
  );
};

export default MonthDays;

const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: "100%",
  }
}
