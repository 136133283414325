import "./AddShiftDayBox.css";
import React, { useCallback, useContext, useEffect, useState } from "react";
import TheTimePicker from "./TheTimePicker";
import { TheTheme } from "../../../assets/theme";
import TheContext from "../../../Storage/theContext";
import { Button, Box, CircularProgress, Checkbox, FormControlLabel, TextField, } from "@mui/material";
import { calculateDuration, } from "../../../Storage/restoreData";
import { getAllProjectOfCustomerNames } from "../../../RES/customer&Project";
import { shiftAUD_V2, } from "../../../RES/user";
import SelectBoxCP from "./SelectBoxOfCP";
import { MinTitle } from "../../../elements/MI-elements";
import STRINGS, { Alerts } from "../../../assets/Strings";
import { toast } from "react-toastify";
import Sounds from "../../../assets/sounds/_exportSounds";

const getTXT = (type, Language, Collection) => {
  return (
    Collection ?
      STRINGS[Collection]?.[type]?.[Language]
      : STRINGS[type][Language] || "err"
  )
}

const createArrayOfShiftsInDay = (workingHourOfTheMonth, day) => {
  if (!workingHourOfTheMonth?.[day]) {
    workingHourOfTheMonth[day] = [];
  }
};

const getDateFromSelectedShift = (keyMonth, day) => {
  const [month, year] = keyMonth.split("/");
  const currentDate = new Date();
  currentDate.setFullYear(year, month - 1, day);
  return currentDate;
};

const createShiftInfoTemplate = (existingShift, currentDate, Language) => {
  return (
    {
      timePick: {
        [getTXT("start", Language)]: existingShift?.checkIn || currentDate,
        [getTXT("end", Language)]: existingShift?.checkOut || null,
      },
      select: {
        [getTXT("customers", Language)]: existingShift?.customer || null,
        [getTXT("projects", Language)]: existingShift?.project || null,
      },
      isZoom: existingShift?.zoomShift || false,
      note: existingShift?.note || ""
    }
  )
}

const AddShiftDayBoxV2 = ({
  day,
  setModalVisible,
  shiftIndex,
  existingShift,
}) => {
  const { user, customers, DateStats, Language, allShifts, setAllShifts } = useContext(TheContext);
  const keyMonth = DateStats.keyMonth.date;
  const currentDate = getDateFromSelectedShift(keyMonth, day);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [ShiftInfo, setShiftInfo] = useState(createShiftInfoTemplate(existingShift, currentDate, Language));
  var ShiftsOfTheMonth = allShifts?.[keyMonth] || {};
  console.log(customers);
  createArrayOfShiftsInDay(ShiftsOfTheMonth, day);
  var arrayOfShiftsInDay = ShiftsOfTheMonth[day];
  const zoomOption = ShiftInfo.select.projects?.projectOption?.zoom; //2999

  const setZoom = () => {
    setShiftInfo({ ...ShiftInfo, ...{ isZoom: !ShiftInfo.isZoom } });
  };

  const RenderTimePick = () => {
    const timePick = Object.keys(ShiftInfo.timePick);
    return (
      <Box sx={styles.selectContainer}>
        {timePick.map((type, i) => (
          <Box key={i} sx={styles.timePickBox}>
            <TheTimePicker
              key={i}
              type={type}
              ShiftInfo={ShiftInfo}
              setShiftInfo={setShiftInfo}
              arrayOfShiftsInDay={arrayOfShiftsInDay}
              shiftIndex={shiftIndex}
              day={day}
              keyMonth={keyMonth}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const createShift = () => {
    const customer = ShiftInfo.select[getTXT("customers", Language)];
    const project = ShiftInfo.select[getTXT("projects", Language)];
    const existingShiftID = existingShift ? { _id: existingShift._id } : {};

    const shift = {
      checkIn: ShiftInfo.timePick[getTXT("start", Language)],
      checkOut: ShiftInfo.timePick[getTXT("end", Language)],
      customer: customer ? { name: customer.name, _id: customer._id } : "",
      project: project ? { name: project.name, _id: project._id } : "",
      zoomShift: ShiftInfo.isZoom,
      note: localStorage.getItem("note"),
      duration: calculateDuration(
        ShiftInfo.timePick[getTXT("start", Language)],
        ShiftInfo.timePick[getTXT("end", Language)]
      ),
      edited: new Date(),
      created: arrayOfShiftsInDay?.[shiftIndex]?.created,
      ...existingShiftID,
    };
    return shift;
  };

  const addingShift = (shift) => {
    if (!allShifts[keyMonth]) {
      allShifts[keyMonth] = {};
    }
    if (!allShifts[keyMonth][day]) {
      allShifts[keyMonth][day] = [];
    }
    if (shiftIndex > -1) {
      allShifts[keyMonth][day][shiftIndex] = { ...shift };
    } else {
      allShifts[keyMonth][day].push(shift);
    }
  };

  const onDone = (shift) => {
    // add shift // url2999
    setIsLoading(true);
    try {
      // userId,shift,keyMonth,keyDay,toDelete,
      toast.promise(
        shiftAUD_V2(user._id, shift, keyMonth, day, false,),
        {
          pending: STRINGS.Loading[Language],
          success: `${STRINGS.success[Language]}✅`,
          error: `${STRINGS.failed[Language]}❌`
        }
      ).then((v) => {
        console.log("🕘", v);
        shift._id = v.data._id
        setIsLoading(false);
        setModalVisible(false);
        addingShift(shift)
        setAllShifts((v) => ({ ...v }))
      }).catch((e) => {
        console.log("🔴", e);
        toast.error(e.message, { autoClose: 1500, });
        setIsLoading(false);
      });
    } catch (e) {
      toast.error("updating data", { autoClose: 1500, });
      setIsLoading(false);
    }
  };

  const DoneBTN = () => {
    const shift = createShift();
    console.log(shift);
    const checkRequired = () => {
      const isToday = day === new Date().getDate()
      const CheckOut = shift.checkOut;
      const defaultCheck = shift.checkIn && shift.customer && shift.project
      return !(isToday ? defaultCheck : defaultCheck && CheckOut);
    };

    const Alert = () => {
      if (checkRequired()) {
        const audio = new Audio(Sounds.error());
        audio.play();
        toast.error(Alerts.RequiredCP[Language], { autoClose: 1500, });
      }
    };

    return (
      <div onMouseEnter={() => shift.note = localStorage.getItem("note")} onClickCapture={() => Alert()}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onDone(shift)}
          disabled={checkRequired()}
        >
          {getTXT("Done", Language)}
        </Button>
      </div>
    );
  };

  const DisplaySelectBox = () => {
    const selects = [{ [getTXT("customers", Language)]: customers || [] }, { [getTXT("projects", Language)]: projects }];

    return (
      <Box sx={styles.selectContainer}>
        {selects.map((select, i) => {
          const key = Object.keys(select)?.[0];
          const value = select[key];
          return (
            <SelectBoxCP
              key={i}
              ShiftInfo={ShiftInfo}
              setShiftInfo={setShiftInfo}
              options={value}
              type={key}
              setP={(v) => setProjects(v)}
            />
          );
        })}
      </Box>
    );
  };

  const DisplayZoom = () =>
    zoomOption ? (
      <FormControlLabel
        control={
          <Checkbox
            checked={ShiftInfo.isZoom}
            color="success"
            onChange={setZoom}
          />
        }
        label={getTXT("zoom", Language)}
      />
    ) : null;

  const Note = () => {
    const [note, setNote] = useState(ShiftInfo.note)
    const handleNote = (e) => {
      let noteValue = e.target.value
      if (noteValue.length <= 70) {
        setNote(noteValue)
        localStorage.setItem("note", noteValue)
      } else {
        alert("note must be less than 70 characters")
      }
    }

    return (
      <TextField
        label="note"
        multiline
        value={note}
        onChange={handleNote}
        maxRows={5}
      />
    )
  }

  const getExistingProject = useCallback(() => {
    //  #useCallback$
    if (existingShift) {
      const body = { _id: existingShift?.customer?._id };
      getAllProjectOfCustomerNames(body).then((v) => {
        setProjects(v.data);
      });
    }
  }, [existingShift]);

  useEffect(() => {
    getExistingProject();
    localStorage.removeItem("note")
  }, [getExistingProject]);

  return (
    <div className="day-box">
      <RenderTimePick />
      <DisplaySelectBox />

      <DisplayZoom />
      <Note />

      {isLoading ? <CircularProgress /> : null}

      <MinTitle>
        {getTXT("Duration", Language)}:
        {calculateDuration(
          ShiftInfo?.timePick[getTXT("start", Language)],
          ShiftInfo?.timePick[getTXT("end", Language)]
        )}
      </MinTitle>

      <DoneBTN />
    </div>
  );
};

export default AddShiftDayBoxV2;

const styles = {
  timePickBox: {
    margin: "10px",
    backgroundColor: TheTheme.main,
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  InputLabel: {
    color: "white",
    padding: "2px",
    borderRadius: "5px",
    backgroundColor: TheTheme.secondary,
    "&.Mui-focused": {
      color: "#fff",
      backgroundColor: TheTheme.secondary,
    },
  },
};