import React, { useContext, useState } from 'react';
import { Box, } from '@mui/material';
import TheContext from '../Storage/theContext';
import { DatePicker, } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const YearMonthPicker = () => {
  const { DateStats, user } = useContext(TheContext);
  const { date, setDate, } = DateStats.keyMonth;
  const [month, year] = date.split('/');
  const formattedDate = dayjs().year(year).month(month - 1).startOf('month');
  const minDate = user?.createdAt ? dayjs(user?.createdAt).startOf('month') : dayjs('2024-01-01');
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const handleDateChange = (date) => {
    if (date) {
      var formatted = dayjs(date).format('MM/YYYY');

      if (formatted.at(0) === '0') {
        formatted = formatted.replace('0', '');
      }
      console.log("formatted", formatted);
      setSelectedDate(formatted);
      setDate(formatted.toString());
    } else {
      setSelectedDate('');
    }
  };

  return (
    <Box sx={styles.box}>
      <DatePicker
        openTo="year"
        views={['year', 'month']}
        maxDate={dayjs()}
        minDate={minDate}
        format={'MM/YYYY'}
        value={dayjs(selectedDate)}
        desktopModeMediaQuery={'@media (max-width:0px)'}
        onAccept={handleDateChange}
      />
    </Box>
  );
};

export default YearMonthPicker;

const styles = {
  box: {
    display: 'flex',
    paddingTop: 1,
    flexDirection: 'column',
    paddingInline: '20%'
  },
  datePicker: {
    bgc: '#000000',
  },
}