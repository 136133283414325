import React, { useContext, useEffect, useState } from "react";
import "./UsersPage.css";
import TheContext from "../../../Storage/theContext";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Autocomplete, TextField } from "@mui/material";
import STRINGS from "../../../assets/Strings";
import { Text } from "../../../elements/MI-elements";
import { getAllUsersData, setActive } from "../../../RES/API";

const UsersPage = () => {
    const { Language } = useContext(TheContext);
    let { usersData } = useLoaderData();
    const [UsersData, setUsersData] = useState(usersData?.data || []);
    const navigate = useNavigate();
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(-1);
    const userTableHead = [
        {
            key: "_level",
            value: STRINGS.level[Language],
        },
        {
            key: "name",
            value: STRINGS.name[Language],
        },
        {
            key: "phoneNumber",
            value: STRINGS.phone[Language],
        },
        {
            key: "_active",
            value: STRINGS.status[Language],
        },
    ]

    const RenderUserTableHead = () => {
        return (
            <TableRow>
                {
                    userTableHead.map((user, index) => (
                        <TableCell sx={styles.tableHed} key={index}>
                            <Text style={{ fontWeight: "bold" }}>{user.value}</Text>
                        </TableCell>
                    ))
                }
            </TableRow>
        )
    }
    const RenderUserTableBody = () => {

        const onCellClick = (user) => {
            console.log("cell", user.name);
            navigate(`/${user.name}`, { unstable_viewTransition: true, state: { user } });
        }
        const onStatusCellClick = (e, user_id, status) => {
            setLoading(user_id);
            let type = "User";
            e.stopPropagation();
            console.log("status", user_id, status);
            setActive(type, user_id, !status).then((res) => {
                if (res?.success) {
                    getData();
                }
                else {
                    alert(res?.message + " something went wrong")
                }
            })
        }

        return UsersData.map((user, i) => (
            <TableRow className="user-row" key={i}>
                {
                    userTableHead.map(({ key }, index) => (
                        <TableCell key={index} onClick={() => onCellClick(user)}>
                            {key === "_active" ?
                                (loading === user._id ?
                                    <span class="loader"></span> :
                                    <div onClick={(e) => onStatusCellClick(e, user._id, user._active)}
                                        className={`status ${user[key]}`}
                                    />
                                )
                                :
                                <Text
                                    className={user._isAdmin && 'admin'} >
                                    {key === "_level" && user._isAdmin ? "⚜️ Admin " : ''}
                                    {user[key]}
                                </Text>
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        ));
    }

    const filterProjects = (data) => {
        if (filter.length === 0) {
            console.log("reset");
            setUsersData(data || [])
            return;
        }
        let to_filter = data || [];
        let filteredProjects = to_filter.filter((user) =>
            filter.some((userSearch) => userSearch._id === user._id
            ));
        setUsersData(filteredProjects);
    }

    const getData = async () => {
        await getAllUsersData().then((res) => {
            filterProjects(res?.data);
        }).finally(() => setLoading(-1));
    }

    useEffect(() => {
        getData();
    }, [filter]);

    return (
        <div className="users-page">
            <h2>all users</h2>
            <Autocomplete
                multiple
                value={filter.name}
                onChange={(event, newValue) => {
                    setFilter(newValue);
                }}
                options={UsersData}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Search" />}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                style={{ maxWidth: '1000px', alignSelf: 'center', width: '100%' }}
            />
            <TableContainer className="TableContainer">
                <Table stickyHeader>
                    <TableHead>
                        <RenderUserTableHead />
                    </TableHead>

                    <TableBody>
                        <RenderUserTableBody />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
};

export default UsersPage;

const styles = {
    tableHed: { backgroundColor: '#1999', fontWeight: 'bold' },
}

