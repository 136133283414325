// export const baseUrl = "http://localhost:2999" // dev localhost
// export const baseUrl = "http://192.168.1.29:2999" // dev local-port-host
// export const baseUrl = "https://code-zone-server.onrender.com" // dev
// export const baseUrl = "https://code-zone-server.vercel.app"// dev vercel
export const baseUrl = "https://codezoneos-server.onrender.com"  // prod

export const fetchApi = async (route, method, body) => {
    const url = baseUrl + route;
    return await fetch(url, {
        method: method || 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body,

    }).then(res => res.json())
        .catch((error) => {
            console.error("fetch Error", error?.message);
        });
}

export const stringify = (body) => (JSON.stringify(body))

export const checkRespond = async () => {
    const route = "/checkRespond";
    return await fetchApi(route, 'GET', null)
}

export const test = async () => {
    const route = "/";
    console.log(" test**");
    // const jsonString = JSON.stringify({"ID":"mosa",});
    return await fetchApi(route, 'GET', null)
}

export const getWorkingHourTableData = async () => {
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`
    const route = `/getWorkingHourTableData${query}`;
    return await fetchApi(route, 'GET', null)
}

export const getAllUsersNames = async () => {
    const route = "/getAllUsersNames";
    return await fetchApi(route, 'GET', null)
}

export const getAllUsersData = async () => {
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`
    const route = `/getAllUsers${query}`;
    return await fetchApi(route, 'GET', null)
}

export const getAllProjects = async () => {
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`
    const route = `/getAllProjects${query}`;
    return await fetchApi(route, 'GET', null)
}

export const findCustomers = async (method) => {
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}&method=${method}`
    const route = `/findCustomers${query}`;
    return await fetchApi(route, 'GET', null)
}

export const getWorkingHourDates = async () => {
    const route = `/getWorkingHourDates`;
    return await fetchApi(route, 'GET', null)
}

export const setActive = async (type, id, state) => {
    let body = { _id: id };
    let query = `?active=${state}`
    const route = `/activateState${type}${query}`;
    return await fetchApi(route, 'PATCH', stringify(body))
}