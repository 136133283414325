import { fetchApi, stringify } from "./API";

export const getAllCustomers = async () => {
    const route = "/getAllCustomer";  
    return await fetchApi(route , 'GET' , null)
}

export const addCustomer = async (body) => {
    const route = "/addCustomer";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const getAllCustomerWithTheProjects = async () => {//new#2999
    let groupId = localStorage.getItem("group-id");
    let query = `?group-id=${groupId}`
    const route = `/getAllCustomerWithTheProjects${query}&_active=1`;
    return await fetchApi(route , 'GET' , null)
}

export const getAllProjectOfCustomer = async (body) => {
    const route = "/getAllProjectOfCustomer";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const getCustomerWithTheProject = async (body) => {
    const route = "/getCustomerWithTheProject";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const getProject = async (body) => {
    const route = "/getProject";  
    return await fetchApi(route , 'POST' , stringify(body))
}

export const getAllProjectOfCustomerNames = async (body) => {
    const route = "/getAllProjectOfCustomerNames"; 
    return await fetchApi(route , 'POST' , stringify(body))
}

export const addCustomerWithProjects = async (body) => {
    const route = "/addCustomerWithProjects";
    console.log("body",body);
    return await fetchApi(route , 'POST' , stringify(body))
}

export const filterShifts = async (body) => {
    const route = "/filterShifts";
    return await fetchApi(route , 'POST' , stringify(body))
}

export const addProjectsToCustomer = async (body) => {
    const route = "/addProjectsToCustomer";
    return await fetchApi(route , 'PATCH' , stringify(body))
}

export const addManyProjects = async (body) => {
    const route = "/addManyProjects";
    return await fetchApi(route , 'post' , stringify(body))
}