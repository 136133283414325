import './UserPage.css';
import React, { useContext, useRef, useState } from 'react';
import { Button, CircularProgress, Checkbox } from '@mui/material';
import FieldInput from '../../../components/FieldInput';
import TheContext from '../../../Storage/theContext';
import { Link, useFetcher, useLocation, useNavigate } from 'react-router-dom';
import { getUserFromStorage } from '../../../Storage/restoreData';
import UnAvailable from '../UnAvailable';
import { Title } from '../../../elements/MI-elements';
import AdminPromise from './AdminPromise';
import STRINGS from '../../../assets/Strings';

const UserPage = () => {
  const { setUser, isDark, Language } = useContext(TheContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  let fetcher = useFetcher();
  let inAction = fetcher.formData != null;
  const user = state?.user || { ...getUserFromStorage() };
  const { name, phoneNumber, password } = user
  const [Edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAdmin = user?._isAdmin;
  const [Admin, setAdmin] = useState(isAdmin);
  const userBody = {
    name: {
      value: name,
      text: STRINGS.name[Language],
    },
    phoneNumber: {
      value: phoneNumber,
      text: STRINGS.phone[Language],
    },
    password: {
      value: password,
      text: STRINGS.pass[Language],
    }
  }

  const RenderTextField = () => {
    const fields = Object.entries(userBody);
    const inputRefs = useRef(fields.reduce((acc, [field]) => {
      acc[field] = React.createRef();
      return acc;
    }, {}));

    const handleNextField = (currentIndex) => {
      const nextIndex = currentIndex + 1;
      if (nextIndex < fields.length) {
        const nextField = fields[nextIndex][0];
        inputRefs.current[nextField].current.focus();
      }
    };

    return (
      fields.map(([key, { value, text }], index) =>
        <div key={index} className='continuer-detail'>
          <div className='detail'>
            {text} :
          </div>
          <FieldInput
            sx={(Edit && (isAdmin || state || (key === "password"))) ? styles.fieldInput : null}
            key={key}
            index={index}
            field={text}
            data={userBody}
            type={key}
            value={value}
            onNextField={handleNextField}
            inputRefs={inputRefs}
            disabled={!(Edit && (isAdmin || state || (key === "password")))}
            lastIndex={fields.length - 1}
            inAction={inAction}
          />
        </div>
      )
    );
  };

  const userChickWC = () => {
    if (user) {
      if (user?._level === "worker") {
        return ({
          main: "worker",
          rout: "working-hour",
          details: {},
          name: STRINGS.userWorkingHour[Language]
        })
      } else if (user?.level?.customer) {
        console.log("user worker", user?.customer);
        return ({
          rout: "customer-info",
          details: user?.level?.customer,
          name: STRINGS.customerInfo[Language]
        })
      } else {
        console.log("user", user);
        return ({
          rout: "/",
          details: '',
          name: 'back'
        })
      }
    }
  };

  const RenderButtons = () => {
    const userState = userChickWC();

    const navigatePath = (path, option) => {
      navigate(path,)
    }

    const LogOut = (path, option) => {
      navigatePath(path, option);
      setUser(null);
      localStorage.removeItem('user');
    }

    const Buttons = [
      {
        name: userState.name,
        linkPath: userState.rout,
        state: { details: userState.details },
        color: "info",
      },
      {
        name: STRINGS.Log_out[Language],
        link: '/',
        option: { replace: true },
        color: "error",
        onClick: LogOut,
      },
    ]

    return (
      <div style={styles.containerBtn}>
        {Buttons.map((btn, i) =>
          <Link unstable_viewTransition key={i} to={btn.linkPath || null} state={btn.state || null}>
            <Button
              sx={{ margin: "5px" }}
              variant={isDark ? "text" : 'outlined'}
              color={btn.color}
              onClick={() => btn?.onClick?.(btn.link, btn.option)}
            >
              {btn.name}
            </Button>
          </Link>
        )}
      </div>
    )
  };

  const DisplayEditButton = () => {

    const handelEdit = () => {
      setUser({ ...getUserFromStorage() })
      setEdit(!Edit)
      setIsLoading(false)
    };

    return (
      <div style={styles.editContainer}>
        {isLoading ? <CircularProgress /> : null}
        <Button
          // disabled={inAction}
          style={styles.editBtn}
          variant="contained"
          color={Edit ? "error" : "secondary"}
          onClick={() => handelEdit()}
        >
          {Edit ?
            STRINGS.cancelEdit[Language] :
            STRINGS.Edit[Language]
          }
        </Button>
      </div>
    )
  }

  const DisplaySaveButton = () => {
    return (
      <Button
        type='submit'
        disabled={inAction}
        sx={{ marginBottom: 5 }}
        variant="outlined"
        color="primary"
      >
        {inAction ?
          STRINGS.saving[Language] :
          STRINGS.Save[Language]
        }
      </Button>
    )
  }

  const IsAdmin = () => {
    return (
      state &&
      <div style={{ color: isDark ? (Edit ? 'silver' : '#ffffff50') : (Edit ? 'black' : '#00000050') }}>
        is admin
        <Checkbox
          onChange={(e) => {
            console.log(e.target.checked);
            
            setAdmin(e.target.checked);
            // setUser({ ...user, _isAdmin: e.target.checked })
          }}
          checked={Admin}
          color='success'
          name='admin'
          disabled={!Edit}
          style={{ color: isDark ? (Edit ? 'silver' : '#ffffff50') : (Edit ? 'black' : '#00000050') }}
        />
        <input
          type='hidden'
          value={Admin}
          name='_admin'
        />
      </div>
    )
  }

  return (
    user && user.name ?
      <>
        <AdminPromise isAdmin={isAdmin} />
        <Title>{user.name}</Title>
        <div style={styles.container}>
          <DisplayEditButton />

          <fetcher.Form method="post">
            <RenderTextField />
            <input
              type='hidden'
              value={JSON.stringify(user)}
              name='old-user'
            />
            <input
              type='hidden'
              value={true}
              name={state ? '' : 'user'}
            />
            <IsAdmin />
            {Edit ?
              <DisplaySaveButton /> :
              null
            }
          </fetcher.Form>

        </div>
        {state ? null : <RenderButtons />}
      </>
      :
      <UnAvailable />
  );
};

export default UserPage;

const styles = {
  editBtn: {
    alignSelf: 'end',
    marginInline: 20
  },
  editContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  fieldInput: {
    backgroundColor: "#4995",
  },
  container: {
    display: "flex",
    // width: "60vh",
    flex: 2,
    flexDirection: 'column',
  },
  containerBtn: {
    display: "flex",
    width: "60vh",
    flex: 1,
    flexDirection: 'column',
  }
}