import { LoadCustomersData, LoadProjectsData, LoadUsersData, LoadWorkingHourSelectData, customersLoader, userCustomerLoader } from "./Loaders.js";
import { createUsers, AddProjectsAction, UserSaveEdits } from "./actions.js";
import { Admin, CustomerPages, MonthDays } from "./pages/exportPages.jsx";
import UsersPage from "./pages/List/UsersPage.jsx";
import AllCustomers from "./pages/List/AllCustomers.jsx";
import AllProjects from "./pages/List/AllProjects.jsx";
const { AllWorkingHour, CreateUser, AddProject } = Admin;
const { ProjectInfo, CustomerInfo } = CustomerPages;

export const workerRouts = [
  {
    loader: customersLoader,
    path: "month-shifts",
    element: <MonthDays />,
  },
  {
    path: "working-hour",
    element: <AllWorkingHour />,
    loader: LoadWorkingHourSelectData,
  },
]

export const customerRouts = [
  {
    path: "customer-info",
    element: <CustomerInfo />,
    loader: userCustomerLoader,
  },
  {
    path: "project-info",
    element: <ProjectInfo />,
  },
]

export const AdminRouts = [
  {
    path: "all-users",
    element: <UsersPage />,
    loader: LoadUsersData,
    action: UserSaveEdits,
  },
  {
    path: "all-customers",
    element: <AllCustomers />,
    loader: LoadCustomersData,
    // action: UserSaveEdits,
  },
  {
    path: "all-projects",
    element: <AllProjects />,
    loader: LoadProjectsData,
    // action: UserSaveEdits,
  },
  {
    path: "create",
    element: <CreateUser />,
    loader: customersLoader,
    action: createUsers,
  },
  {
    path: "create/add-project",
    element: <AddProject />,
    loader: customersLoader,
    action: AddProjectsAction,
  },
]